import 'wdyr'
import React, { Fragment, useEffect } from 'react'
import { NextPage } from 'next'
import Head from 'next/head'
import { css, Global } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useFavicon, useMount } from 'react-use'
import { debugAPI } from '~/modules/SDK/debug/debugAPI'
import { gaAPI } from '~/utils/gaAPI'
import { __DEV__ } from '~/utils/__DEV__'
import { __PRODUCTION__ } from '~/utils/__PRODUCTION__'
import { AppProps } from 'next/app'
import BackendDataComponent from '~/modules/auth/BackendDataComponent'
import PostNextPageRenderComponent from '~/modules/auth/PostNextPageRenderComponent'
import { AuthRootProvider } from '~/modules/auth/AuthRootProvider'
import { AUTH_MODULE_DISABLED_LIST, STATIC_DATA_DISABLED_LIST } from '~/modules/auth/moduleConfig'
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents'
import { snapshot } from 'valtio'

if (__PRODUCTION__) {
  gaAPI.initPageViewOnRouteChange()
}

/**
 * Example
 * https://github.com/mui-org/material-ui/blob/next/examples/nextjs-with-typescript/pages/_app.tsx
 */
const MyApp: NextPage<AppProps> = props => {
  const { Component, pageProps } = props
  const pageTarget = fr_agents.targetPages
  const disableAuth = AUTH_MODULE_DISABLED_LIST.includes(pageTarget)
  const disableStaticData = STATIC_DATA_DISABLED_LIST.includes(pageTarget)

  debugAPI.me.log(`[_app.page] agentStore.config -> `, { config: snapshot(fr_agents.config) })

  useMount(() => {
    async function main() {
      const window_ = globalThis as AnyExplicit
      const { default: _ } = await import('lodash')
      const { default: dayjs } = await import('~/utils/dayAPI')

      // 允許在 console panel 使用 libraries 調試代碼
      window_._ = _
      window_.dayAPI = dayjs

      // 允許在 console panel 使用 debugAPI 來檢視 logs
      window_.debugAPI = debugAPI
    }
    main()
  })

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && !__PRODUCTION__) {
      jssStyles.parentElement?.removeChild(jssStyles)
    }
  }, [])

  useFavicon(fr_agents.config.favicon)

  return (
    <Fragment>
      <Head>
        <title>{fr_agents.config.title}</title>
        <meta
          name='viewport'
          content='initial-scale=1, width=device-width, maximum-scale=1'
        />
      </Head>
      <Fragment>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {fr_agents.config.theme ? (
            <ThemeProvider theme={fr_agents.config.theme}>
              <CssBaseline />
              <Global
                styles={css`
                  html {
                    scroll-behavior: smooth;
                  }

                  /* The styles here as base styles through all of the projects. */
                  #__next {
                    display: block;
                    min-height: 100vh;

                    a {
                      text-decoration: none;
                    }
                  }
                  @page {
                    size: auto; /* auto is the initial value */
                    margin: 0mm; /* this affects the margin in the printer settings */
                  }
                `}
              ></Global>

              <AuthRootProvider>
                <Component {...pageProps}></Component>
              </AuthRootProvider>

              {disableStaticData ? null : <BackendDataComponent />}
              {disableAuth ? null : <PostNextPageRenderComponent />}
            </ThemeProvider>
          ) : (
            <Fragment>
              <CssBaseline />
              <Global
                styles={css`
                  html {
                    scroll-behavior: smooth;
                  }
                  /* The styles here as base styles through all of the projects. */
                  #__next {
                    display: block;
                    min-height: 100vh;
                    a {
                      text-decoration: none;
                    }
                  }
                  @page {
                    size: auto; /* auto is the initial value */
                    margin: 0mm; /* this affects the margin in the printer settings */
                  }
                `}
              ></Global>
              <AuthRootProvider>
                <Component {...pageProps}></Component>
              </AuthRootProvider>
              {disableStaticData ? null : <BackendDataComponent />}
              {disableAuth ? null : <PostNextPageRenderComponent />}
            </Fragment>
          )}
        </LocalizationProvider>
      </Fragment>
    </Fragment>
  )
}

export default MyApp
