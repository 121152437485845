import { Dayjs } from 'dayjs'
import { IsAny } from 'tsdef'
import { LiteralUnion } from 'type-fest'
import { Urlu } from '~/modules/SDK/app/urlu/Urlu'
import {
  DailyRankKey,
  DailyRankResourceData,
  OrderBy,
} from '~/modules/screener/containers/useStockScreenerResource'
import dayAPI from '~/utils/dayAPI'
import { expectType } from '~/utils/tsd'

export class StockScreenerAPI extends Urlu {
  dailyRank = new Urlu.Query(
    (params: {
      volume_gte: number
      volume_lte: number
      amount_gte: number
      amount_lte: number
      date?: string
      limit?: number
      type?: DailyRankKey
      days?: number
      threshold?: number
      order_by?: OrderBy
      sort_by?: LiteralUnion<
        | 'symbol'
        | 'close'
        | 'change'
        | 'change_percent'
        | 'amplitude'
        | 'open'
        | 'high'
        | 'low'
        | 'volume'
        | 'amount',
        string
      >
    }) => {
      return this.request.axios
        .get<DailyRankResourceData[]>(`/daily_rank`, {
          params,
        })
        .then(res => res.data)
    },
  )

  intraday = new Urlu.Query((params?: { symbol?: string }) => {
    return this.request.axios
      .get<{ date: string }>('/latest_trade_date', {
        params: {
          symbol: params?.symbol || '',
          intraday: true,
        },
      })
      .then(res => dayAPI(res.data.date).startOf('day'))
  })

  latestTradeDays = new Urlu.Query((params?: { symbol?: string }) => {
    return this.request.axios
      .get<{ date: string }[]>(`/latest_trade_date`, {
        params: {
          symbol: params?.symbol || '',
          size: 30,
        },
      })
      .then(res => res.data.map(datum => dayAPI(datum.date).startOf('day')))
  })
}

/* istanbul ignore next */
function TypingTesting() {
  const api = new StockScreenerAPI(``)

  api.intraday.fetch().then(foo => {
    expectType<Dayjs>(foo)

    // @ts-expect-error 不能是 any（所以要修到沒紅線）
    expectType<IsAny<typeof foo, true, false>>(foo)
  })

  const res = api.intraday.useAsync()

  expectType<Dayjs | undefined>(res.data)
  expectType<Error | undefined>(res.error)

  // @ts-expect-error 不能是 any（所以要修到沒紅線）
  expectType<IsAny<typeof res.data, true, false>>(res.data)
}
